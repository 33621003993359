<template>
  <div class="ratioBar pt-3">
    <div class="label-prog">Progress</div>
    <div class="current-value">
      <span>
        {{ current + " " + unit }}
      </span>
    </div>
    <div class="bar">
      <div
        class="currentValue"
        :class="percentP.pg !== 0 ? 'available' : ''"
        :style="`width: ${percentP.pg}%`"
      ></div>
    </div>
    <div class="info d-flex justify-content-between">
      <div
        class="
					right
					text-default-value
					d-flex
					justify-content-center
					align-items-center
				"
      >
        <span>
          {{ current + " " + unit }}
        </span>
      </div>
      <div
        class="
					left
					text-default-value
					d-flex
					justify-content-center
					align-items-center
				"
      >
        <span>
          {{ current + " " + unit }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // "percent", "start", "end", "current"
  props: {
    percent: {
      type: Number,
      default: 0
    },
    start: {
      type: Number,
      default: 0
    },
    end: {
      type: Number,
      default: 0
    },
    current: {
      type: Number,
      default: 0
    },
    unit: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      isActive: false
    };
  },
  computed: {
    percentP() {
      if (this.percent >= 90) {
        return {
          current: 70,
          pg: this.percent >= 100 ? 100 : this.percent
        };
      }
      if (this.percent <= 5) {
        return {
          current: 5,
          pg: this.percent
        };
      }
      return {
        current: this.percent + 2,
        pg: this.percent
      };
    }
  },
  created() {
    this.isActive = this.$route.meta.page == "trade";
  }
};
</script>

<style lang="scss" scoped>
.fx-15 {
  font-size: 15px;
  font-weight: bold;
}
.fx-20 {
  font-size: 20px;
  font-weight: bold;
}
.ratioBar {
  color: #f9ff00;
  text-transform: capitalize;
  font-family: "UTM Helve";
  margin-top: 10px;
  margin-bottom: 0;
  padding: 5px;
  position: relative;
  .current-value {
    position: absolute;
    top: -10px;
    left: 0;
    right: 0;
    margin: auto;
    width: max-content;
  }
  .label-prog {
    position: absolute;
    top: -10px;
    font-weight: 600;
  }
  .bar {
    height: 15px;
    width: 100%;
    border-radius: 10px;

    background-color: #ffffffad;
    overflow: hidden;

    .currentValue {
      background: linear-gradient(to right, rgba(23, 192, 1, 1) 0%, rgba(0, 56, 1, 1) 100%);
      height: 15px;
      border-radius: 10px;
      &.available {
        box-shadow: 1.2794px 1.2794px 1.2794px rgba(0, 0, 0, 0.3);
      }
    }
  }
  .bar.white {
    background-color: white !important;
  }
  .info {
    font-size: 0.8em;
  }
}
.current-value,
.text-default-value {
  font-size: 16px;
  font-weight: 700;
}
.pt-3 {
  padding-top: 1.25rem;
}
.justify-content-between {
  justify-content: space-between;
}
.d-flex {
  display: flex;
}
.justify-content-center {
  justify-content: center;
}
.align-items-center {
  align-items: center;
}
</style>
