<template>
  <div class="popup-container">
    <div class="popup-shadow" :class="{ 'winner-drop': winner }" @click="closePopup()"></div>
    <div class="popup-content">
      <div class="popup-header">{{ title }}</div>
      <div class="popup-body">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ""
    },
    winner: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    closePopup() {
      this.$emit("clicked", true);
    }
  }
};
</script>

<style lang="scss" scoped>
.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 1090;
  display: flex;
  justify-content: center;
  align-items: center;
  .popup-shadow {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 2000;
    &.winner-drop {
      background: url(~@/assets/images/luckyspin/win-drop.png), rgba(0, 0, 0, 0.5);
      background-size: cover;
      background-repeat: no-repeat;
      background-blend-mode: overlay;
    }
  }
  .popup-content {
    position: relative;
    // min-height: 400px;
    // width: 100%;
    // max-width: 1200px;
    z-index: 2010;
    // @media (max-width: 1439px) {
    //   max-width: 1000px;
    // }
    // @media (max-width: 1024px) {
    //   max-width: 757px;
    // }
    // @media (max-width: 767px) {
    //   max-width: calc(100% - 15px);
    // }
  }
}
</style>
