<template>
  <base-layout>
    <div class="lucky-container">
      <div class="container-fluid">
        <div class="title-lucky">
          <img src="~@/assets/images/luckyspin/title.png" alt="" />
        </div>
        <div class="content-lucky">
          <div class="left" v-if="widthL >= 1200">
            <img class="fish-img" src="~@/assets/images/luckyspin/fish.png" alt="" />
            <div class="buy-box box-1">
              <div class="title">Lucky Ticket</div>
              <img src="~@/assets/images/luckyspin/ticket-img.png" alt="" />
              <div class="input-group">
                <div class="button-append">
                  <button variant="none" :disabled="buytick <= 1" @click="buytick--">
                    <i class="fa fa-minus"></i>
                  </button>
                </div>

                <input
                  min="1"
                  step="1"
                  require
                  type="number"
                  v-model.number="buytick"
                  class="input-amount"
                />

                <div class="button-append">
                  <button variant="none" @click="buytick++">
                    <i class="fa fa-plus"></i>
                  </button>
                </div>
              </div>
              <div class="total-amount">Total: {{ buytick * Settings.price }}$</div>
            </div>
            <div class="buy-action my-3">
              <button class="btn button-lucky" @click="buyNow">
                Buy
              </button>
            </div>
          </div>
          <div class="center">
            <div class="spin-box">
              <div class="box-spin">
                <div class="wheel-wrapper">
                  <div class="wheel-pointer">
                    <img src="~@/assets/images/luckyspin/circle.png" alt="" />
                    <div class="btn button-lucky info">
                      {{ StatisLucky.spin }}
                    </div>
                  </div>
                  <div
                    class="wheel-bg"
                    :class="{ freeze: freeze }"
                    :style="
                      `transform: rotate(${wheelDeg}deg);transition-timing-function:ease-in-out;`
                    "
                  >
                    <div class="prize-list">
                      <div class="prize-item-wrapper" v-for="(item, index) in Reward" :key="index">
                        <div
                          class="prize-item"
                          :style="`transform: rotate(${(360 / prizeList.length) * index}deg)`"
                        >
                          <div
                            class="prize-name"
                            :class="
                              `${item.replace(' ', '').toLowerCase()} item_${item
                                .replace(' ', '')
                                .toLowerCase()}`
                            "
                          >
                            <img :src="require(`@/assets/images/luckyspin/${item}.png`)" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="amount-box">
              <img src="~@/assets/images/luckyspin/ticket-img.png" alt="" />
              <div class="amount">{{ Balance.Ticket }}</div>
            </div>
            <div class="action-box">
              <button class="btn button-lucky" @click="onClickRotate(1)">
                Spin
              </button>
              <!-- <button
							class="btn button-lucky"
							@click="onClickRotate(100)"
							v-if="user.user === '840463'"
						>
							Spin 100
						</button> -->
              <button class="btn button-lucky" @click="onClickRotate(Balance.Ticket)">
                Spin All
              </button>
            </div>
          </div>
          <div class="left" v-if="widthL < 1200">
            <img class="fish-img" src="~@/assets/images/luckyspin/fish.png" alt="" />
            <div class="buy-box box-1">
              <div class="title">Lucky Ticket</div>
              <img src="~@/assets/images/luckyspin/ticket-img.png" alt="" />
              <div class="input-group">
                <div class="button-append">
                  <button variant="none" :disabled="buytick <= 1" @click="buytick--">
                    <i class="fa fa-minus"></i>
                  </button>
                </div>

                <input
                  min="1"
                  step="1"
                  require
                  type="number"
                  v-model.number="buytick"
                  class="input-amount"
                />

                <div class="button-append">
                  <button variant="none" @click="buytick++">
                    <i class="fa fa-plus"></i>
                  </button>
                </div>
              </div>
              <div class="total-amount">Total: {{ buytick * Settings.price }}$</div>
            </div>
            <div class="buy-action my-3">
              <button class="btn button-lucky" @click="buyNow">
                Buy
              </button>
            </div>
          </div>
          <div class="right">
            <div class="mission-box box-2">
              <div class="mission-title">
                <img src="~@/assets/images/luckyspin/title-mission.png" alt="" />
              </div>
              <div class="mission-content">
                <div
                  class="item-mission"
                  v-for="(item, index) in Mission"
                  :key="`${index}_${item.id}`"
                >
                  <div class="item-icon">
                    <img
                      :src="require(`@/assets/images/luckyspin/m${index + 1}.png`)"
                      width="100%"
                      height="100%"
                      alt=""
                    />
                  </div>
                  <div class="item-title">
                    {{ item.name }}
                  </div>
                  <div class="item-content">
                    <div class="content-detail">
                      {{ item.description }}
                    </div>
                    <div class="content-progress">
                      <ProgressMission
                        :percent="(item.current / item.step) * 100"
                        :start="0"
                        :end="item.end"
                        :current="item.current"
                        :unit="item.unit"
                      />
                    </div>
                    <div class="content-reward">
                      <div class="reward-title">
                        Reward
                      </div>
                      <div class="reward-content">
                        <div class="reward-left">
                          <span>1 Lucky Ticket</span>
                          <img
                            :style="item.current >= item.step ? 'filter: grayscale(100%);' : ''"
                            src="~@/assets/images/luckyspin/ticket-img.png"
                            alt=""
                          />
                        </div>
                        <div class="reward-right">
                          <div>Expired:</div>
                          <span>{{ item.expired }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="balance-box box-2">
              <div class="bonus">
                <div class="balance-title">Balance Bonus</div>
                <div class="amount">
                  <CurrencyFormat :value="Balance.Voucher" />
                </div>
                <button class="btn button-withdraw" @click="$modal.show('withdrawprofit')">
                  Withdraw
                </button>
              </div>
              <div class="balance-cur">
                <div class="balance-title">Balance Main</div>
                <div class="amount">
                  <CurrencyFormat :value="Balance.Main" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <v-modal
        id="withdrawprofit"
        name="withdrawprofit"
        :width="425"
        :height="'auto'"
        :adaptive="true"
      >
        <div class="title-withdraw">
          <h2>Bonus Withdrawal table</h2>
          <span @click="$modal.hide('withdrawprofit')">x</span>
        </div>
        <form @submit.prevent="onWithdrawVoucher()" style="padding:10px;" class="form-wd">
          <div class="text-center text-warning">
            The trader will be eligible for withdrawal when the trading volume is 100 times the
            amount you want to withdraw
          </div>

          <div class="form-group">
            <label for="" class="display-block">
              Maximum Withdraw Bonus
            </label>
            <input
              type="text"
              class="form-control"
              style="background: #ffffff4f"
              disabled
              :value="TruncateToDecimals(StatisLucky.max_withdraw)"
            />
          </div>
          <div class="form-group">
            <label for="" class="display-block">
              Withdraw Bonus Remaining
            </label>
            <input
              type="text"
              disabled
              class="form-control"
              style="background: #ffffff4f"
              :value="TruncateToDecimals(StatisLucky.remaining_withdraw)"
            />
          </div>
          <div class="form-group">
            <label for="" class="display-block">
              Balance Bonus Available
            </label>
            <input
              type="text"
              class="form-control"
              style="background: #ffffff4f"
              disabled
              :value="TruncateToDecimals(Balance.Voucher)"
            />
          </div>
          <div class="form-group">
            <label for="" class="display-block">
              Amount Withdraw
            </label>
            <input type="number" class="form-control" v-model.number="withdraw.amount" />
          </div>
          <div
            class="
            form-group
            text-center
            d-flex
            justify-content-center
        "
          >
            <button
              class="btn button-withdraw border"
              type="submit"
              :disabled="
                !withdraw.amount ||
                  withdraw.amount <= 0 ||
                  withdraw.amount > parseFloat(Balance.Voucher)
              "
            >
              Withdraw
            </button>
          </div>
        </form>
      </v-modal>
      <Modal
        v-if="ShowReward"
        :key="`modal_reward_${reward.list.length}`"
        id="reward-show"
        :class="reward.list.length == 1 && reward.id == 5 ? 'more-turn' : ''"
        @clicked="onClose"
        :winner="reward.list.length == 1 && reward.id == 1 ? false : true"
      >
        <div class="content-reward text-center">
          <button
            type="button"
            aria-label="Close"
            class="close btn-custome-close"
            @click="ShowReward = false"
          >
            <img src="~@/assets/images/luckyspin/close.png" alt="" srcset="" />
          </button>
          <div class="failed-label" v-if="reward.list.length == 1 && reward.id == 1">
            <img src="~@/assets/images/luckyspin/lose.png" alt="" />
          </div>
          <div
            v-else-if="reward.list.length == 1 && reward.id == 5"
            class="one-turn position-relative"
          >
            <div class="win-after">
              <img src="~@/assets/images/luckyspin/r-left.png" alt="" class="r-left" />
              <img src="~@/assets/images/luckyspin/r-right.png" alt="" class="r-right" />
            </div>
            <span class="label-reward">
              <img src="~@/assets/images/luckyspin/win.png" alt="" />
            </span>
            <br />
            <span class="sub-title">
              <img src="~@/assets/images/luckyspin/TicketR.png" width="300" alt="" />
            </span>
          </div>
          <div v-else class="px-lg-4 px-2 position-relative">
            <div class="win-after">
              <img src="~@/assets/images/luckyspin/r-left.png" alt="" class="r-left" />
              <img src="~@/assets/images/luckyspin/r-right.png" alt="" class="r-right" />
            </div>

            <span class="label-reward">
              <img src="~@/assets/images/luckyspin/win.png" alt="" />
            </span>
            <br />
            <Carousel
              :per-page="1"
              :mouse-drag="true"
              :navigation-enabled="true"
              :autoplay="true"
              :autoplay-timeout="3000"
              :per-page-custom="[
                [320, 1],
                [400, 2],
                [576, 3],
                [768, 4],
                [1024, 5],
                [1199, 7]
              ]"
              :center-mode="true"
              :loop="false"
              navigation-next-label="<i class='fa fa-chevron-right color-white'></i>"
              navigation-prev-label="<i class='fa fa-chevron-left color-white'></i>"
            >
              <Slide v-for="(key, value) in reward.show" :key="`${key}_${value}`">
                <div class="box-reward">
                  <div class="numberReward">{{ key }}</div>
                  <img
                    :src="require(`@/assets/images/luckyspin/${value}.png`)"
                    class="reward-item"
                    :class="`reward_${value.replace(' ', '').toLowerCase()}`"
                    alt=""
                  />
                </div>
              </Slide>
            </Carousel>
          </div>
        </div>
      </Modal>
    </div>
  </base-layout>
</template>

<script>
import ProgressMission from "@/components/ProgressMission.vue";
import { mapMutations, mapState, mapGetters } from "vuex";
import CurrencyFormat from "@/components/CurrencyFormat.vue";
import baseAPI from "@/services/base";
import { Carousel, Slide } from "vue-carousel";
import BaseLayout from "./layouts/Base";
import Modal from "@/components/luckyspin/Modal.vue";
export default {
  components: {
    ProgressMission,
    CurrencyFormat,
    Carousel,
    Slide,
    BaseLayout,
    Modal
  },
  data() {
    return {
      freeze: false,
      rolling: false,
      wheelDeg: 0,
      buytick: 1,
      //   prizeNumber: 11,
      reward: {
        list: [],
        id: null,
        show: {}
      },
      withdraw: {
        amount: 0
      },
      aTerval: 0,
      languageName: "gb-eng",
      widthL: 1920,
      ShowReward: false
    };
  },
  computed: {
    ...mapState(["isLoad"]),
    ...mapGetters({
      Balance: "spin/Balance",
      Mission: "spin/Mission",
      Reward: "spin/Reward",
      Settings: "spin/Settings",
      StatisLucky: "spin/StatisLucky"
    }),
    prizeNumber() {
      return this.Reward.length;
    },
    prizeList() {
      return this.Reward.slice(0, this.prizeNumber);
    }
  },
  methods: {
    hidemodal(name) {
      this.$modal.hide(name);
    },
    async onWithdrawVoucher() {
      const token = await this.genarateCaptChaV3("lucky");
      if (!token) {
        this.$toast.error("Too Fast! Please Wating Recaptcha.", "Buy Ticket Failed");
        return;
      }
      if (this.Balance.Voucher < this.withdraw.amount) {
        this.$toast.error("Your balance is not enough!", "Buy Ticket Failed");
        return;
      }
      this.$store.dispatch("spin/req_postWithdraw", {
        token: token,
        amount: this.withdraw.amount
      });
    },

    async onClickRotate(quantiy) {
      const t = this;
      if (this.rolling) {
        return;
      }
      this.rolling = true;
      const token = await this.genarateCaptChaV3("lucky");
      if (!token) {
        this.$toast.error("Too Fast! Please Wating Recaptcha.", "Spin Failed");
        return;
      }
      if (quantiy > 500) {
        quantiy = 500;
        this.$toast.success(
          "The maximum number of tickets you can spin in one time is 500.",
          "Successfully"
        );
      }
      if (t.Balance.Ticket < quantiy || t.Balance.Ticket <= 0) {
        this.$toast.error("Your Ticket is not enough.", "Spin Failed");
        return;
      }
      this.reward = {
        list: [],
        id: null,
        show: {}
      };
      this.wheelDeg += 100;
      if (quantiy > 50) {
        t.aTerval = setInterval(() => {
          this.wheelDeg += 100;
        }, 20);
      } else {
        t.aTerval = setInterval(() => {
          this.wheelDeg += 100;
        }, 100);
      }
      await baseAPI()
        .post("spin/play", {
          quantity: quantiy,
          token: token
        })
        .then(response => {
          if (response) {
            const reward = response.data.data.reward;
            clearInterval(t.aTerval);
            const { wheelDeg, prizeList } = this;
            this.wheelDeg =
              wheelDeg - (wheelDeg % 360) + 4 * 360 + (360 - (360 / prizeList.length) * reward.id);
            setTimeout(() => {
              this.rolling = false;
              this.reward.list = reward.name;
              this.reward.id = reward.id;
              var count = {};
              this.reward.list.forEach(function(i) {
                count[i] = (count[i] || 0) + 1;
              });
              this.reward.show = count;
              this.ShowReward = true;
            }, 4500);
          }
        });
    },
    loadinfo() {
      this.$store.dispatch("spin/req_getInfo");
    },
    async buyNow() {
      const token = await this.genarateCaptChaV3("lucky");

      if (this.buytick <= 0) {
        this.$toast.error("Please Buy Ticket equal to than 1.", "Buy Ticket Failed");
        return;
      }
      if (!token) {
        this.$toast.error("Too Fast! Please Wating Recaptcha.", "Buy Ticket Failed");
        return;
      }
      if (this.Balance.Main < this.buytick * 5) {
        this.$toast.error("Your balance is not enough!", "Buy Ticket Failed");
        return;
      }
      this.$store.dispatch("spin/req_postBuyTicker", {
        quantity: this.buytick,
        token: token
      });
    },
    roll(result) {
      this.rolling = true;
      const { wheelDeg, prizeList } = this;
      this.wheelDeg =
        wheelDeg - (wheelDeg % 360) + 6 * 360 + (360 - (360 / prizeList.length) * result);
      setTimeout(() => {
        this.rolling = false;
        alert("Result：" + prizeList[result]);
      }, 4500);
    },
    TruncateToDecimals(num, mah, dec = 2) {
      var calcDec = Math.pow(10, dec);
      var newNum = Math.trunc(num * calcDec) / calcDec;
      const d = newNum.toString().split(".")[1];
      const precision = d ? (d.length > 2 ? 2 : d.length) : 0;
      return ` ${mah ? mah : ""}${newNum.toFixed(precision).replace(/\d(?=(\d{3})+\.)/g, "$&,")}`;
    },
    checkResizeWindow() {
      this.widthL = window.innerWidth;
    },
    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    },
    onClose(payload) {
      if (payload) {
        this.ShowReward = false;
      }
    }
  },
  async mounted() {
    //   this.$modal.show('reward-show')
    // simulate load data
    this.timeLoop = setInterval(() => {
      this.remainTime = this.remainTime > 0 ? this.remainTime - 1 : 0;
    }, 1000);
    this.checkResizeWindow();
    this.windowEvent = () => {
      this.checkResizeWindow();
    };
    window.addEventListener("resize", this.windowEvent);
    window.onresize = function() {
      this.widthL = window.innerWidth;
    };
  },
  created() {
    this.$store.dispatch("spin/req_getInfo");
    const t = this;
    this.unsubscribe = this.$store.subscribe(mutation => {
      switch (mutation.type) {
        case "spin/BUY_SUCCESS":
          this.$modal.hide("buyTicket");
          break;
        case "core/ERROR_MESSANGE":
          clearInterval(t.aTerval);
          t.wheelDeg = 0;
          break;
        case "spin/WITHDRAW_SUCCESS":
          this.$modal.hide("withdrawprofit");
          this.$store.dispatch("spin/req_getInfo");
          break;
      }
    });
  },
  beforeDestroy() {
    this.unsubscribe();
  },
  destroyed() {
    clearInterval(this.timeLoop);
  },
  watch: {
    prizeNumber() {
      this.freeze = true;
      this.wheelDeg = 0;

      setTimeout(() => {
        this.freeze = false;
      }, 0);
    },
    ShowReward: function(newVal, oldVal) {
      if (newVal === false) {
        this.loadinfo();
      }
    }
  }
};
</script>
<style lang="scss">
.button-withdraw {
  &.border {
    border: 1px solid #fff;
  }
  border: 0px;
  background: rgba(23, 192, 1, 1) !important;
  background: linear-gradient(90deg, rgba(23, 192, 1, 1) 0%, rgba(0, 56, 1, 1) 60%) !important;
  min-width: 120px;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.3);
  color: #fff;
  font-size: 15px;
  font-family: "Myriad Pro";
  text-transform: uppercase;
  display: flex;
  font-weight: 700;
  text-align: center;
  justify-content: center;
  letter-spacing: 1px;
  height: 30px;
  vertical-align: middle;
  line-height: 20px;
  border-radius: 15px;
  &:hover {
    filter: brightness(150%);
  }
  &:focus,
  &:active {
    filter: brightness(150%);
    border: 2px solid #f9ff00;
  }
}
.d-flex {
  display: flex;
}
.justify-content-center {
  justify-content: center !important;
}
.flex-column {
  flex-direction: column !important;
}
#reward-show {
  .VueCarousel-navigation-prev {
    left: 21px !important;
  }
  .VueCarousel-navigation-next {
    right: 21px !important;
  }
  &.more-turn {
    .sub-title {
      img {
        width: 250px;
      }
    }
    @media (min-width: 768px) {
      .popup-content {
        max-width: 500px !important;
        height: 300px !important;
        .popup-body {
          max-width: 500px !important;
          height: 300px !important;
          .content-reward {
            max-width: 500px !important;
            height: 300px !important;
            .label-reward {
              top: -30px !important;
              img {
                height: 60px !important;
              }
            }
          }
        }
      }
    }
  }
  .popup-content {
    min-height: 400px;
    width: 100%;
    max-width: 1200px;
    @media (max-width: 1439px) {
      max-width: 1000px;
    }
    @media (max-width: 1024px) {
      max-width: 757px;
    }
    @media (max-width: 767px) {
      max-width: calc(100% - 15px);
    }
    // @media (max-width: 576px) {
    // 	max-width: 400px;
    // }
    // @media (max-width: 425px) {
    // 	max-width: 300px;
    // }
    .popup-body {
      min-height: 400px;
      width: 100%;
      background: transparent !important;
      background-image: unset !important;
      box-shadow: unset !important;
      border: 0 none !important;
      .one-turn {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        min-height: 300px;
      }
      .content-reward {
        min-height: 370px;
        width: 100%;
        background: #0200446b !important;
        border: 2px solid #f2c010;
        .btn-custome-close {
          height: 30px;
          width: 30px;
          border-radius: 50%;
          z-index: 100;
          opacity: 1 !important;
          top: -5px;
          position: absolute;
          right: -5px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .label-reward {
          top: -45px;
          position: absolute;
          left: 0;
          right: 0;
          margin: auto;
          z-index: 10;
          img {
            height: 85px;
            width: auto;
          }
          @media (min-width: 1440px) {
            top: -60px;
            img {
              height: 120px;
            }
          }
          @media (max-width: 767px) {
            top: -35px;
            img {
              height: 70px;
            }
          }
          @media (max-width: 500px) {
            top: -25px;
            img {
              height: 50px;
            }
          }
          @media (max-width: 374px) {
            top: -20px;
            img {
              height: 40px;
            }
          }
        }
        .win-after {
          .r-left,
          .r-right {
            height: 250px;
            position: absolute;
            top: -90px;
            @media (max-width: 767px) {
              height: 200px;
              top: -60px;
            }
            @media (max-width: 500px) {
              height: 140px;
              top: -50px;
            }
            @media (max-width: 374px) {
              height: 100px;
              top: -20px;
            }
          }
          .r-left {
            left: 0;
          }
          .r-right {
            right: 0;
          }
        }
        .color-white {
          color: #fff !important;
          opacity: 1 !important;
        }
        .VueCarousel-wrapper {
          padding: 70px 10px 20px 10px;

          .box-reward {
            position: relative;
            background: #ffffff1c;
            width: 150px;
            height: 200px;
            border: 1px solid #f2c010ab;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            margin-left: auto;
            margin-right: auto;
            padding: 5px;
            .numberReward {
              background: url("~@/assets/images/luckyspin/num.png");
              background-repeat: no-repeat;
              background-size: 100%100%;
              color: #f2c010;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 45px;
              height: 45px;
              position: absolute;
              padding: 2px;
              bottom: -20px;
              right: 0;
              left: 0;
              margin: auto;
              font-size: 15px;
              font-weight: bold;
            }
            .reward-item {
              width: 100%;
              height: auto;
              max-height: 170px;

              &.reward_goodluck {
                transform: rotate(40deg);
                width: auto;
              }
              &.reward_ticket {
                transform: rotate(220deg);
                width: auto;
              }
            }
          }
        }
      }
    }
    .failed-label {
      padding: 20px;
      img {
        max-width: 600px;
        width: 100%;
      }
    }
  }
}
#withdrawprofit {
  .vm--overlay {
    background: rgba(19, 12, 14, 0.8) !important;
  }
  .vm--modal {
    background: rgba(35, 21, 32, 0.55) !important;
    border: 2px solid #009444;
    padding: 10px;
  }
}
</style>
<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@600;700;800;900&display=swap");
.lucky-container {
  .container-fluid {
    padding-left: 0;
    padding-right: 0;
  }
  font-weight: 400;
  font-size: 15px;
  padding-bottom: 40px;
  min-height: 100vh;
  @media (max-width: 425px) {
    margin-left: -15px;
    margin-right: -5px;
  }
  .button-lucky {
    background: rgba(23, 192, 1, 1) !important;
    background: linear-gradient(90deg, rgba(23, 192, 1, 1) 0%, rgba(0, 56, 1, 1) 100%) !important;
    min-width: 120px;
    box-shadow: 3.6641px 3.4981px 2.3321px rgba(0, 0, 0, 0.5);
    color: #fff;
    font-size: 18px;
    font-family: "Myriad Pro";
    text-transform: uppercase;
    display: flex;
    font-weight: 700;
    text-align: center;
    justify-content: center;
    letter-spacing: 1px;
    height: 40px;
    vertical-align: middle;
    line-height: 30px;
    border: 0px;
    &:hover {
      filter: brightness(150%);
    }
    &:focus,
    &:active {
      filter: brightness(150%);
      border: 2px solid #f9ff00;
    }
    &.info {
      position: absolute;
      left: 0;
      right: 0;
      top: 60%;
      height: 20px;
      width: 60px;
      margin: auto;
      min-width: unset;
      padding: 0;
      display: flex;
      // align-items: center;
      justify-content: center;
      box-shadow: unset;
      line-height: 21px;
      font-size: 20px;
      background: rgb(201, 0, 0) !important;
      background: radial-gradient(circle, rgba(201, 0, 0, 1) 0%, rgba(92, 0, 0, 1) 60%) !important;
    }
  }
  .box-1 {
    background-color: rgba(0, 0, 0, 0.4);
    box-shadow: 2px 2px 0px rgba(0, 0, 0, 0.5);
    border-radius: 15px;
    border: 2px solid #009444;
  }
  .box-2 {
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 15px;
    border: 2px solid #009444;
  }
  .title-lucky {
    text-align: center;
    margin: 50px 0;
    padding: 0 5px;
    img {
      width: 100%;
      max-width: 500px;
      filter: drop-shadow(5.9px 5.9px 4.2px rgba(0, 0, 0, 0.5));
    }
  }
  .content-lucky {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    .left {
      flex: 0 0 25%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .fish-img {
        max-width: 250px;
        margin-bottom: 15px;
      }
      .buy-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 10px;
        max-width: 300px;
        .total-amount,
        .title {
          font-family: "Montserrat", sans-serif;
          font-size: 25px;
          color: #f9ff00;
          font-weight: 700;
          letter-spacing: 0.5px;
          background: transparent;
        }
        img {
          max-width: 150px;
        }
        .input-group {
          margin: 10px 5px;
          max-width: 270px;
          display: flex;
          justify-content: center;
          button {
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 0 none !important;
            padding: 5px 10px;
            background: transparent;
            flex: 0 0 30px;
            i,
            svg {
              font-size: 25px;
              color: #85e8ff;
            }
          }
          .input-amount {
            background: white;
            border: 0;
            height: 30px;
            color: #8b8686 !important;
            font-weight: 700;
            font-size: 18px;
            text-align: center;
            flex: 0 0 210px;
            max-width: 200px;
          }
        }
      }
    }
    .center {
      flex: 0 0 50%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-direction: column;
      .box-spin {
        position: relative;
        height: 550px;
        width: 550px;

        .wheel-wrapper {
          width: 550px;
          height: 550px;
          position: absolute;
          top: 0;
          left: 0;
        }

        .wheel-pointer {
          width: calc(100% / 3);
          height: calc(100% / 2.3);
          border-radius: 1000px;
          position: absolute;
          z-index: 990;
          cursor: pointer;
          text-align: center;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          img {
            z-index: 990;
            width: 100%;
            height: auto;
          }
        }
        .wheel-bg {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          overflow: hidden;
          transition: transform 4s ease-in-out;
          background: url(~@/assets/images/luckyspin/spin.png);
          background-size: 100%100%;
          &.freeze {
            transition: none;
            background: red;
          }
        }

        .prize-list {
          width: 100%;
          height: 100%;
          position: relative;
          text-align: center;
        }

        .prize-item-wrapper {
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          width: calc(100% / 2);
          height: calc(100% / 2);
        }

        .prize-item {
          width: 100%;
          height: 100%;
          transform-origin: bottom;

          .prize-name {
            padding: 0% 0 0% 20%;
            transform: rotate(90deg);
            display: flex;
            font-size: 20px;
            color: white;
            font-weight: 600;
            text-align: center;
            justify-content: center;
            align-self: center;
            align-items: center;
            margin: auto;
            text-transform: capitalize;
            img {
              transform: rotate(-90deg) translateY(10px) translateX(0px);
              width: 80px;
              filter: drop-shadow(0px 0px 2px rgba(255, 0, 0, 0.5));
              @media (max-width: 1439px) {
                width: 75px;
              }
            }
            &.item_7777 {
              img {
                width: 95px;
                @media (max-width: 480px) {
                  width: 75px;
                  transform: rotate(-90deg) translateY(10px) translateX(0px);
                }
                @media (max-width: 424px) {
                  width: 70px;
                  transform: rotate(-85deg) translateY(10px) translateX(0px);
                }
                @media (max-width: 374px) {
                  width: 60px;
                  transform: rotate(-90deg) translateY(10px) translateX(0px);
                }
              }
            }
            &.item_5555 {
              img {
                width: 90px;
                @media (max-width: 767px) {
                  width: 80px;
                }
                @media (max-width: 480px) {
                  width: 65px;
                  transform: rotate(-90deg) translateY(10px) translateX(0px);
                }
                @media (max-width: 424px) {
                  width: 55px;
                  transform: rotate(-85deg) translateY(10px) translateX(0px);
                }
                @media (max-width: 374px) {
                  width: 50px;
                  transform: rotate(-90deg) translateY(10px) translateX(0px);
                }
              }
            }
          }

          .prize-icon {
          }
        }
      }
      .prize-item .prize-name {
        font-size: 25px !important;
        font-weight: 700;
      }
      .prize-list .prize-item-wrapper .prize-name.goodluck {
        padding: 0 15px 0 20%;
        img {
          width: 40px;
        }
      }
      .prize-list .prize-item-wrapper .prize-name.ticket {
        padding: 0 0 0 25%;
        transform: rotate(87deg);
        img {
          transform: rotate(93deg) translateY(10px) translateX(0px);
          width: 35px;
        }
      }
      .amount-box {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 20px 0;
        flex: 1;
        width: 100%;
        max-width: 245px;
        img {
          max-width: 100px;
          margin-right: 5px;
        }
        .amount {
          box-shadow: 2px 2px 0px rgba(0, 0, 0, 0.5);
          background-color: #282627;
          border-radius: calc(30px * 1.5);
          height: 30px;
          width: 100%;
          display: flex;
          font-family: "Montserrat", sans-serif;
          font-size: 22px;
          border: #fff 1px solid;
          color: #f9ff00;
          font-weight: 700;
          text-align: center;
          vertical-align: middle;
          justify-content: center;
          align-items: center;
          letter-spacing: 1px;
        }
      }
      .action-box {
        display: flex;
        justify-content: space-around;
        flex: 1;
        width: 100%;
        max-width: 320px;
      }
    }
    .right {
      flex: 0 0 25%;
      flex-direction: column;
      height: 100%;
      .mission-box {
        height: 400px;
        position: relative;
        padding: 30px 5px 15px 10px;
        .mission-title {
          position: absolute;
          top: -25px;
          height: 50px;
          width: 80%;
          min-width: 250px;
          left: 0;
          right: 0;
          margin: auto;
          text-align: center;
          img {
            height: 100%;
            width: auto;
          }
        }
        .mission-content {
          overflow-x: hidden;
          max-height: 375px;
          height: 100%;
          padding-top: 35px;
          padding-right: 5px;
          overflow-y: auto;
          border-radius: 15px;
          &::-webkit-scrollbar {
            width: 10px;
            height: 3px;
            border-radius: 1.5px;
          }

          /* Track */
          &::-webkit-scrollbar-track {
            background: rgba(0, 0, 0, 0.288);
            border-radius: 5px;
            &:hover {
              background: #ffffffad;
            }
            margin: {
              bottom: 65px;
              top: 65px;
            }
          }

          /* Handle */
          &::-webkit-scrollbar-thumb {
            background: #ffffff;
            border-radius: 5px;

            box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.5);
          }

          /* Handle on hover */
          &::-webkit-scrollbar-thumb:hover {
            background: #f9ff00;
          }

          .item-mission {
            border: 1px solid #a1a1a1;
            position: relative;
            background: #282627;
            border-radius: 12px;
            margin-bottom: 40px;
            &:last-child {
              margin-bottom: 5px;
            }
            // height: 250px;
            .item-icon {
              position: absolute;
              width: 70px;
              height: 70px;
              border-radius: 50px;
              left: 20px;
              top: -35px;
              z-index: 1;
              @media (min-width: 1200px) and (max-width: 1440px) {
                left: 0;
              }
              @media (max-width: 425px) {
                width: 50px;
                height: 50px;
                left: 0;
                top: -25px;
              }
            }
            .item-title {
              position: absolute;
              height: 40px;
              width: 80%;
              max-width: 275px;
              min-width: 235px;
              top: -20px;
              background: rgba(23, 192, 1, 1) !important;
              background: linear-gradient(
                90deg,
                rgba(0, 56, 1, 1) 0%,
                rgba(23, 192, 1, 1) 50%,
                rgba(0, 56, 1, 1) 100%
              ) !important;
              box-shadow: 2px 3.4981px 2.3321px rgba(0, 0, 0, 0.3);
              left: 60px;
              border-radius: 10px;
              padding-left: 20px;
              color: #fff;
              font-weight: 600;
              display: flex;
              justify-content: center;
              padding-right: 8px;
              align-items: center;
              text-align: center;
              border: 0px;
              @media (min-width: 1200px) and (max-width: 1440px) {
                left: 50px;
              }
              @media (max-width: 425px) {
                left: 30px;
                min-width: 200px;
              }
            }
            .item-content {
              height: 100%;
              padding: 40px 10px 5px;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              .content-detail {
                color: #fff;
                margin: {
                  bottom: 5px;
                  top: 5px;
                }
                text-align: left;
              }
              .content-progress {
              }
              .content-reward {
                margin-top: 15px;
                .reward-title {
                  color: #f9ff00;
                  text-transform: capitalize;
                  font-family: "UTM Helve";
                  font-size: 16px;
                  font-weight: bold;
                }
                .reward-content {
                  margin-top: 5px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  .reward-left {
                    flex: 1 1 auto;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-right: 2px solid white;
                    img {
                      height: 30px;
                      margin-left: 5px;
                    }
                    span {
                      color: #fff;
                      font-size: 15px;
                      font-weight: 600;
                    }
                  }
                  .reward-right {
                    flex: 1 1 auto;
                    padding-left: 10px;
                    color: #fff;
                    display: flex;
                    justify-content: space-around;
                    span {
                      color: #f9ff00;
                      font-weight: bold;
                      margin-left: 10px;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .balance-box {
        margin: 5px 0;
        height: 200px;
        min-height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 35px 15px 15px;
        .balance-title {
          position: absolute;
          height: 40px;
          width: 80%;
          // max-width: 275px;
          // min-width: 250px;
          border: 1px solid #ffffff;
          top: -20px;
          background: rgba(23, 192, 1, 1) !important;
          background: linear-gradient(
            90deg,
            rgba(0, 56, 1, 1) 0%,
            rgba(23, 192, 1, 1) 50%,
            rgba(0, 56, 1, 1) 100%
          ) !important;
          box-shadow: 2px 3.4981px 2.3321px rgba(0, 0, 0, 0.3);
          left: 0;
          right: 0;
          margin: auto;
          border-radius: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fff;
          font-weight: 700;
        }
        .amount {
          color: #f9ff00;
          text-transform: capitalize;
          font-family: "UTM Helve";
          font-size: 18px;
          font-weight: bold;
        }
        .bonus {
          position: relative;
          border-top-left-radius: 15px;
          border-bottom-left-radius: 15px;
          border-right: 0 !important;
          &:after {
            content: "";
            height: 70px;
            width: 2px;
            background: #fff;
            top: 0;
            bottom: 0;
            margin: auto;
            right: 0;
            position: absolute;
          }
          .button-withdraw {
            &.border {
              border: 1px solid #fff;
            }
            border: 0px;
            background: rgba(23, 192, 1, 1) !important;
            background: linear-gradient(
              90deg,
              rgba(23, 192, 1, 1) 0%,
              rgba(0, 56, 1, 1) 60%
            ) !important;
            min-width: 120px;
            box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.3);
            color: #fff;
            font-size: 15px;
            font-family: "Myriad Pro";
            text-transform: uppercase;
            display: flex;
            font-weight: 700;
            text-align: center;
            justify-content: center;
            letter-spacing: 1px;
            height: 30px;
            vertical-align: middle;
            line-height: 20px;
            border-radius: 15px;
            &:hover {
              filter: brightness(150%);
            }
            &:focus,
            &:active {
              filter: brightness(150%);
              border: 2px solid #f9ff00;
            }
          }
        }
        .balance-cur {
          border-top-right-radius: 15px;
          border-bottom-right-radius: 15px;
          border-left: 0 !important;
        }
        .bonus,
        .balance-cur {
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          padding: 35px 5px 20px 5px;
          flex: 1;
          background: #282627;
          border: 1px solid #a1a1a1;
          height: 100%;
        }
      }
    }
    @media (min-width: 1200px) and (max-width: 1440px) {
      .left {
        flex: 0 0 20%;
        .fish-img {
          width: 210px;
        }
      }
      .center {
        flex: 0 0 50%;
        .box-spin {
          width: 525px;
          height: 525px;
          .wheel-wrapper {
            width: 525px;
            height: 525px;
          }
        }
      }
      .right {
        flex: 0 0 30%;
      }
    }
    @media (max-width: 1440px) {
      flex-wrap: wrap;
      .left {
        flex: 0 0 30%;
      }

      .center {
        flex: 1 1 auto;
      }
      .right {
        flex: 0 0 100%;
        flex-direction: column-reverse;
        display: flex;
        align-items: flex-start;
        margin-top: 50px;
        .mission-box,
        .balance-box {
          flex: 1;
          margin: 5px;
          width: 100%;
        }
        .mission-box {
          .mission-content {
            .item-mission {
              .item-content {
                .content-reward {
                  .reward-content {
                    .reward-right {
                      justify-content: center;
                      text-align: center;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    @media (max-width: 1199px) {
      //   .center {
      //     flex: 0 0 100%;
      //     margin-bottom: 50px;
      //   }
      // }
      // @media (max-width: 991px) {
      align-items: flex-start;
      .left {
        flex: 0 0 50%;
        display: flex;
        justify-content: center;
        flex-direction: column;
      }

      .center {
        flex: 0 0 100%;
        margin-bottom: 50px;
      }
      .right {
        flex: 0 0 50%;
        flex-direction: column-reverse;
        display: flex;
        align-items: flex-start;
        margin-top: unset;
        .mission-box,
        .balance-box {
          flex: 1;
          margin: 5px;
          width: 100%;
        }
        .mission-box {
          .mission-content {
            .item-mission {
              .item-content {
                .content-reward {
                  .reward-content {
                    .reward-right {
                      justify-content: center;
                      text-align: center;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    @media (max-width: 767px) {
      .center {
        .box-spin {
          height: 480px;
          width: 480px;

          .wheel-wrapper {
            width: 480px;
            height: 480px;
          }
          .prize-item {
            .prize-name {
              padding: 0% 0 0% 20%;
              transform: rotate(90deg);
              img {
                width: 60px;
              }
            }
          }
          .prize-list .prize-item-wrapper .prize-name.goodluck {
            padding: 0 15px 0 30%;
            // transform: rotate(87deg);
            img {
              width: 25px;
            }
          }
          .prize-list .prize-item-wrapper .prize-name.ticket {
            padding: 0 15px 0 40%;
            transform: rotateZ(87deg);
            img {
              width: 25px;
            }
          }
        }
      }
      .left {
        flex: 1;
        width: 100%;
        position: relative;
        .fish-img {
          //   position: absolute;
          //   right: 0;
          //   top: -50px;
          //   overflow-x: hidden;
          //   max-width: 200px;
        }
        .buy-box,
        .buy-action {
          z-index: 10;
        }
      }
      .right {
        flex: 1;
        width: 100%;
      }
    }
    @media (max-width: 480px) {
      .center {
        .box-spin {
          height: 425px;
          width: 425px;

          .wheel-wrapper {
            width: 425px;
            height: 425px;
          }
        }
      }
    }
    @media (max-width: 424px) {
      .center {
        .box-spin {
          height: 370px;
          width: 370px;

          .wheel-wrapper {
            width: 370px;
            height: 370px;
          }
          .prize-item {
            .prize-name {
              padding: 0% 0 0% 20%;
              transform: rotate(85deg);
              img {
                width: 45px;
              }
            }
          }
          .prize-list .prize-item-wrapper .prize-name.goodluck {
            padding: 0 15px 0 30%;
            transform: rotate(90deg);
            img {
              width: 25px;
            }
          }
          .prize-list .prize-item-wrapper .prize-name.ticket {
            padding: 0 5px 0 30%;
            transform: rotate(87deg);
            img {
              width: 25px;
            }
          }
        }
      }
    }
    @media (max-width: 374px) {
      .center {
        .box-spin {
          height: 310px;
          width: 310px;

          .wheel-wrapper {
            width: 310px;
            height: 310px;
          }
          .prize-item {
            .prize-name {
              padding: 0% 0 0% 20%;
              transform: rotate(90deg);
              img {
                width: 40px;
              }
            }
          }
          .prize-list .prize-item-wrapper .prize-name.goodluck {
            padding: 0 15px 0 30%;
            transform: rotate(90deg);
            img {
              width: 20px;
            }
          }
          .prize-list .prize-item-wrapper .prize-name.ticket {
            padding: 0 15px 0 40%;
            transform: rotate(87deg);
            img {
              width: 20px;
            }
          }
        }
      }
      .right {
        .balance-box {
          flex-direction: column;
          height: 100%;
          .bonus,
          .balance-cur {
            flex: 1 1 100%;
            width: 100%;
            margin-bottom: 25px;
            min-height: 100px;
            &:after {
              display: none;
            }
            border-radius: 15px;
            justify-content: center;
            .button-withdraw {
              margin-top: 15px;
            }
          }
        }
      }
    }
  }
}
.input-group {
  display: flex;
}
.title-withdraw {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  position: relative;
  border-bottom: 2px solid #ccc;
  h2 {
    margin: 0;
    text-align: left;
    padding-left: 5px;
    color: #fff;
    font-weight: 600;
    font-size: 25px;
    text-transform: uppercase;
    @media (max-width: 375px) {
      text-align: center;
      font-size: 22px;
    }
  }
  span {
    font-size: 30px;
    line-height: 30px;
    font-weight: 800;
    color: #fff800;
    position: absolute;
    top: -10px;
    right: -5px;
    cursor: pointer;
  }
}
.my-3 {
  margin: {
    top: 1.25rem;
    bottom: 1.25rem;
  }
}
.text-warning {
  color: #fff800 !important;
  font-weight: 600;
  margin: {
    top: 10px;
    bottom: 20px;
  }
}
.form-wd {
  padding-top: 20px;
  label {
    color: #fff;
  }
  input {
    &:disabled {
      background-color: #464445 !important;
    }
    background-color: #282627;
    color: #fff;
    font-weight: 700;
    font-size: 17px;
  }
}
</style>
